<template>
  <div class="main">
    <div class="backPage" @click="$router.go(-1)">返回</div>
    <heand :tagnum="tagnum"></heand>
    <div class="detsils_ziytop">
      <img src="../../assets/industrial/chuangx_top8.png" alt />
    </div>

    <div class="chuangx_fffbox">
      <dl class="yuanx_dl">
        <dd>
          <h1>{{ bonedata.company_name }}</h1>
          <span>{{ bonedata.status }}</span>
          <span v-if="bonedata.isfive">{{ bonedata.isfive }}</span>
          <span v-if="bonedata.quoted_company">{{
            bonedata.quoted_company
          }}</span>
          <span v-if="bonedata.high_new">{{ bonedata.high_new }}</span>
          <span v-if="bonedata.tab">{{ bonedata.tab }}</span>
          <span v-if="bonedata.unicorn">{{ bonedata.unicorn }}</span>
          <span v-if="bonedata.dengl">{{ bonedata.dengl }}</span>
        </dd>
        <dt v-if="newx == 1">
          <!-- <strong>转为项目</strong> -->
          <span class="collect" v-if="collect == 2" @click="clickcollect">
            <img src="../../assets/industr/ubcj.png" alt />收藏
          </span>
          <span class="collect" v-if="collect == 1" @click="clickcollect">
            <img src="../../assets/industr/shoucang2.png" alt />取消收藏
          </span>
        </dt>
      </dl>
      <ul class="yuanx_ul">
        <li>联系电话： {{ bonedata.telephone }}</li>
        <li>电子邮箱：{{ bonedata.email }}</li>
        <li>官网：{{ bonedata.web_site }}</li>
        <li>地址：{{ bonedata.address }}</li>
        <li>
          公司简介：
          <div class="xm_about">{{ bonedata.company_info }}</div>
        </li>
      </ul>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">基本信息</h1>
      <div class="xx_table">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td style="width: 8%; background: #f3f6fb">法定代表人</td>
            <td style="width: 25%">{{ bonedata.legal }}</td>
            <td style="width: 11%; background: #f3f6fb">经营状态</td>
            <td style="width: 20%">{{ bonedata.status }}</td>
            <td style="width: 10%; background: #f3f6fb">成立日期</td>
            <td>{{ bonedata.build_date }}</td>
          </tr>
          <tr>
            <td style="background: #f3f6fb">注册资本</td>
            <td>{{ bonedata.capital }}</td>
            <td style="background: #f3f6fb">统一社会信用代码</td>
            <td>{{ bonedata.social_code }}</td>
            <td style="background: #f3f6fb">2019年营收</td>
            <td>{{ bonedata.taking }}</td>
          </tr>
          <tr>
            <td style="background: #f3f6fb">参保人数</td>
            <td>{{ bonedata.bao }}</td>
            <td style="background: #f3f6fb">企业类型</td>
            <td>{{ bonedata.entype }}</td>
            <td style="background: #f3f6fb">所属行业</td>
            <td>{{ bonedata.industry }}</td>
          </tr>
          <tr>
            <td style="background: #f3f6fb">所在地址</td>
            <td>
              <!-- {{bonedata.}} -->
              <span class="cheng_zi" @click="addZoom()">查看地图 ></span>
            </td>
            <td style="background: #f3f6fb">经营范围</td>
            <td colspan="3">{{ bonedata.scope }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="chuangx_fffbox" v-if="newx == 1">
      <h1 class="chuang_tit">企业供应链图谱</h1>
      <div class="chuangx_map chuangx_map2">
        <RelationGraph
          ref="seeksRelationGraph"
          :options="graphOptions"
          :on-node-click="onNodeClick"
          :on-line-click="onLineClick"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div class="chuangx_fffbox" v-if="newx == 1">
      <h1 class="chuang_tit">企业供应链地图</h1>
      <div class="chuangx_map" style="height: 864px">
        <baidu-map
          class="bm-view"
          :center="centers"
          :double-click-zoom="double"
          :zoom="zooms"
          :dragging="dragging"
          :scroll-wheel-zoom="true"
        >
          <!-- <bm-control>#2959BF
              <button @click="addZoom()">还原</button>
          </bm-control>-->
          <!-- <bm-label
              v-for="(item,index) in district"
              :key="index"
              :content="item.name"
              :position="item.jwd"
              :labelStyle="{color: 'red', fontSize : '12px'}"
          />-->
          <bm-marker
            v-for="(item, index) in alilte"
            :key="'one' + index"
            :position="item.jwd"
            :dragging="false"
            :title="item.name"
          ></bm-marker>
          <!-- <bm-boundary
              name="河南"
              :strokeWeight="1"
              strokeColor="blue"
              fillColor="#2959BF"
          ></bm-boundary>-->
        </baidu-map>

        <!-- <baidu-map v-if="!showmap" class="bm-view" :center="center2" :zoom="zoom2">
            <bm-control>
              <button @click="addZoom()">还原</button>
            </bm-control>
            <bm-marker
              v-for="item in alilte"
              :key="'alilte'+item.id"
              :position="item.jwd"
              :dragging="false"
              @mouseout="out"
              @mouseover="hover"
            ></bm-marker>
        </baidu-map>-->
      </div>
    </div>
    <div class="map" v-if="showmap">
      <baidu-map
        class="bm-view"
        :center="center"
        :zoom="zoom"
        :double-click-zoom="double"
        :scroll-wheel-zoom="true"
      >
        <bm-control>
          <button @click="addZoom()">取消</button>
        </bm-control>
        <bm-marker :position="center" :dragging="false"></bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
var alltp = {
  rootId: "",
  links: [],
  nodes: [],
}; // 二级图谱数据
import {
  backbonedetail,
  backbonecollect,
  detailsAbout,
  detailsAboutfind,
  getsemicmap,
  details360,
  companyDetail,
  zsdetail,
} from "@/api/index";
import RelationGraph from "relation-graph"; // 图谱
export default {
  name: "chdetsils1",
  components: { RelationGraph },
  data() {
    return {
      tagnum: "",
      id: "",
      center: { lat: "", lng: "" },
      collect: "",
      bonedata: [],
      showmap: false,
      zoom: 15,
      // 散点
      double: false,
      dragging: false,
      centers: { lng: 116, lat: 39 }, //经纬度
      zooms: 5, //地图展示级别
      alilte: [],
      // 图谱
      graphOptions: {
        backgrounImage: "",
        backgrounImageNoRepeat: false,
        layouts: [
          {
            label: "自动布局",
            layoutName: "force",
            layoutClassName: "seeks-layout-force",
            // label: "中心",
            // layoutName: "center",
            // layoutClassName: "seeks-layout-center"
          },
        ],
        defaultLineMarker: {
          markerWidth: 20,
          markerHeight: 20,
          refX: 3,
          refY: 3,
          data: "M 0 0, V 6, L 4 3, Z",
        },
        allowShowMiniNameFilter: false,
        isMoveByParentNode: false,
        defaultExpandHolderPosition: "right",
        defaultNodeBorderWidth: 0,
        defaultLineColor: "rgba(254, 106, 0, 1)",
        defaultNodeShape: 0,
        allowShowMiniToolBar: true,
      },
      allrootid: [],
      choice: "",
      newx: 1,
    };
  },
  created() {
    console.log(999999);
    console.log(this.$route);
    this.id = this.$route.query.id;
    this.tagnum = this.$route.query.tagnum;
    this.newx = this.$route.query.new;
    // if (this.newx) {
    this.newx = 1;
    if (this.tagnum == 1) {
      this.getbackbonedetail();
    } else {
      this.getdetails360();
    }
    // }
    // this.companyDetail();
  },
  methods: {
    async getbackbonedetail() {
      const res = await backbonedetail({
        id: this.id,
      });
      this.bonedata = res;
      this.collect = res.collect;
      this.center.lat = res.jwd.lat;
      this.center.lng = res.jwd.lng;
      this.choice = res.choice;
      this.getdetails(res.company_name);
      this.getsemicmap(res.company_name);
    },
    async getdetails360() {
      console.log(this.id);
      const res = await details360({
        id: this.id,
      });
      this.bonedata = res;
      this.collect = res.collect;
      this.center.lat = res.jwd.lat;
      this.center.lng = res.jwd.lng;
      this.getdetails(res.company_name);
      this.getsemicmap(res.company_name);
      this.choice = res.choice;
    },
    async companyDetail() {
      console.log(this.id, 11111111112);
      const res = await companyDetail({
        id: this.id,
      });
      console.log(res, 8887777);
      // if(res.)
      this.bonedata = res;
      this.collect = res.collect;
      this.center.lat = res.jwd.lat;
      this.center.lng = res.jwd.lng;
      this.choice = res.choice;
    },
    // async getzsdetail() {
    //   const res = await zsdetail({
    //     id: this.id
    //   });
    //   this.bonedata = res;
    //   this.collect = res.collect;
    //   this.center.lat = res.jwd.lat;
    //   this.center.lng = res.jwd.lng;
    //   this.choice = res.choice;
    //   this.getdetails(res.company_name);
    //   this.getsemicmap(res.company_name);
    // },
    async clickcollect() {
      const res = await backbonecollect({
        id: this.id,
        collect: this.collect,
        choice: this.choice,
      });
      if (res.code == 0) {
        this.collect = res.collect;
      }
    },
    addZoom() {
      this.showmap = !this.showmap;
    },
    // 获取图谱数据
    async getdetails(name) {
      const res = await detailsAbout({
        company_name: name,
      });
      this.showSeeksGraph(res);
      alltp.nodes.unshift(res.nodes[0]);
      alltp.rootId = res.nodes[0].id;
    },
    // 图谱
    async threetupu(name, id) {
      const res = await detailsAboutfind({
        category: id,
        name: name,
      });
      if (res.nodes.length == 0) {
        return false;
      } else {
        for (var i = 0; i < res.links.length; i++) {
          alltp.links.push(res.links[i]);
        }
        for (var i = 0; i < res.nodes.length; i++) {
          alltp.nodes.push(res.nodes[i]);
        }
      }
      this.showSeeksGraph(alltp);
    },
    showSeeksGraph(query) {
      var __graph_json_data = query;
      this.$refs.seeksRelationGraph.setJsonData(
        __graph_json_data,
        (seeksRGGraph) => {
          // 这些写上当图谱初始化完成后需要执行的代码
        }
      );
    },
    onNodeClick(nodeObject, $event) {
      if (this.allrootid.indexOf(nodeObject.category) == -1) {
        this.allrootid.push(nodeObject.category);
        this.threetupu(nodeObject.text.split("↓")[0], nodeObject.category);
      } else {
        return false;
      }
    },
    onLineClick(lineObject, $event) {},
    async getsemicmap(name) {
      const res = await getsemicmap({
        company_name: name,
      });
      this.alilte = res;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
  font-family: "PingFangSC";
}
.map {
  width: 1000px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.collect {
  height: 34px;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.detsils_ziytop {
  margin: 20px auto 0 auto;
  width: 1360px;
  height: 200px;

  img {
    width: 100%;
  }
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(70, 17, 17) !important;
  }
}
.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .chuang_tit {
    font-size: 18px;
    color: #333;
    padding: 16px;
  }
  .chuangx_jsh {
    font-size: 15px;
    line-height: 26px;
    padding: 0 16px;
  }
  .chuangx_map {
    padding: 0 16px;
    height: 300px;
  }
  .chuangx_map2 {
    height: 500px;
  }
  .xx_table {
    border: solid 1px #eee;
    border-bottom: 0;
    margin: 0 20px;
    td {
      font-size: 14px;
      padding: 12px;
      border-bottom: solid 1px #eee;
      line-height: 28px;
      .cheng_zi {
        color: #fe6a00;
        margin-left: 25px;
        cursor: pointer;
      }
    }
  }

  .yuanx_dl {
    overflow: hidden;
    zoom: 1;
    margin: 30px 30px 20px 30px;
    padding-bottom: 25px;
    border-bottom: solid 1px #eee;

    dd {
      float: left;
      width: 600px;

      img {
        width: 78px;
        float: left;
        margin-right: 25px;
      }
      h1 {
        font-size: 34px;
        margin-bottom: 15px;
      }
      span {
        background: #fff5ed;
        color: #fe6a00;
        padding: 5px 15px;
        margin-right: 15px;
      }
    }
    dt {
      float: right;
      padding-top: 20px;

      strong {
        background: #fe6a00;
        color: #fff;
        border-radius: 3px;
        padding: 7px 25px;
        font-weight: normal;
        font-size: 15px;
        margin-right: 30px;
      }

      span {
        img {
          vertical-align: middle;
        }
      }
    }
  }

  .yuanx_ul {
    overflow: hidden;
    zoom: 1;
    margin: 0 30px 15px 30px;

    li {
      font-size: 15px;
      float: left;
      list-style: none;
      margin-right: 40px;
      line-height: 40px;
    }

    li:last-child {
      margin-right: 0;
    }

    .xm_about {
      width: 1220px;
      float: right;
      line-height: 28px;
      margin-top: 6px;
    }
  }
}
/deep/.c-node-text {
  font-size: 10px;
}
</style>
